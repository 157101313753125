import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { jsx } from "slate-hyperscript";
import { Editor, Point, createEditor, fragment, node } from 'slate'
import { Slate, Editable, withReact, useSlateSelection } from 'slate-react'
import { Transforms } from 'slate';
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import Toolbar from './Toolbar/Toolbar';
import { getMarked, getBlock } from './utils/SlateUtilityFunctions.js'
import './Editor.css'
import { htmlToNode } from "./utils/htmltofragment";



const insertNode = async (editor, type, text) => {


	if (type === 'inputComponent') {

		const inputComponent = { type: 'inputComponent', children: [{ text: "***", removeFull: true }] };

		Transforms.insertNodes(editor, inputComponent);

	} else if (type === 'selectComponent') {
		const selectComponent = { type: 'selectComponent', children: [{ text: " ", options: text, removeFull: true }] };
		Transforms.insertNodes(editor, selectComponent);
	} else {


		const fragment2 = await htmlToNode(text)
	
		const forsqenceRendring = async () => {
			fragment2.map(async (nodes) => {

				let paragraph = { type: "paragraph", children: [nodes] };
				Transforms.insertNodes(editor, paragraph)


			})
			return true
		}

		await forsqenceRendring()
	}
};

const Element = (props) => {
	return getBlock(props);
}
const Leaf = ({ attributes, children, leaf }) => {
	children = getMarked(leaf, children);
	return <span {...attributes}>{children}</span>
}

const MagicNote = ({ templates, variables }) => {
	const [showPopup, setShowPopup] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [variablesearchText, setvariableSearchText] = useState("");

	const [cursorPosition, setCursorPosition] = useState(null);
	const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
	const [selectedVariableIndex, setSelectedVariableIndex] = useState(0);
	const [showvariablepopup, setshowvariablepopup] = useState(false)
	const [searched, setsearched] = useState()
	const [filteredTemplates, setFilteredTemplates] = useState([]);
	const [seacrchedVariableItems, setseacrchedVariableItems] = useState([])
	const containerRef = useRef(null);
	const [nodenameOnEnter, setnodenameOnEnter] = useState('')



	useEffect(() => {


	}, [showvariablepopup])

	const appendTemplate = async (templateName) => {

		const template = templates.find((t) => t.name === templateName);

		if (template) {
			Editor.deleteBackward(editor, { unit: 'word' });
			Editor.deleteBackward(editor, { unit: 'character' });
			const desc = replaceVariablesByValues(template.desc);
			const nodes = convertInputToNodes(desc);
		
			for (let i = 0; i < nodes.length; i++) {
				if (nodes[i].type === 'custom-input') {

					await insertNode(editor, 'inputComponent', "***");

				} else if (nodes[i].type === 'custom-select') {
					await insertNode(editor, 'selectComponent', nodes[i].text);
				} else {
					await insertNode(editor, 'paragraph', nodes[i].text);
			
				}

			}
			setSearchText("");
			setShowPopup(false);
			// 
		}
	};

	const appendvariable = (variableName) => {

		const variable = variables.find((v) => v.name === variableName);

		if (variable) {
			Editor.deleteBackward(editor, { unit: 'word' });
			Editor.deleteBackward(editor, { unit: 'character' });
			const desc = replaceVariablesByValues(variable.desc);
			const nodes = convertInputToNodes(desc);
			for (let i = 0; i < nodes.length; i++) {
				insertNode(editor, 'paragraph', nodes[i].text);
			}
			setvariableSearchText("")
			setshowvariablepopup(false)
			// 
		}


	}


	useEffect(() => {
		//this  useEffect  is used to tackel Error the while selecting the templet using Enter 

		setTimeout(() => {
			if (nodenameOnEnter !== '') {
				appendTemplate(nodenameOnEnter)
				setnodenameOnEnter("")
			}
		}, 10);
	}, [nodenameOnEnter])

	const replaceVariablesByValues = (string) => {
		let output = string;
		const regex = /(@\w+?@)/g;
		const matches = output.matchAll(regex);

		for (const match of matches) {
			const identifier = match[0].substring(1, match[0].length - 1);
			const value = variables.find((t) => t.name === identifier);
			if (value) {
				output = output.replace(match[0], value.desc);
			}
		}
		return output;
	}

	const convertInputToNodes = (input) => {

	
		const nodes = [];
		const regex = /(\*\*\*|\[\[.*?\]\])/g;
		const parts = input.split(regex).filter((part) => part !== undefined);

		for (let i = 0; i < parts.length; i++) {
			const part = parts[i]

			if (part === "***") {
				nodes.push({
					type: 'custom-input',
				})
			} else if (part.startsWith("[[") && part.endsWith("]]")) {
				const options = part.slice(2, -2).split(", ");

				nodes.push({
					type: 'custom-select',
					text: options
				})
			} else {
				if (parts[i] === "</p>") {
					nodes.push({
						type: 'paragraph',
						text: ` .${parts[i]}`,
					});
				} else {
			
					nodes.push({
						type: 'paragraph',
						text: parts[i],
					});

				}

			}
		}

		return nodes;
	};

	const getTextBeforeCursor = useCallback(() => {
		const [cursor] = Editor.nodes(editor, { at: editor.selection });

		if (cursor) {
			const [node, path] = cursor;

			const range = {
				anchor: Editor.start(editor, path),
				focus: editor.selection.anchor,
			};

			return Editor.string(editor, range);
		}

		return '';
	}, []);

	const handleKeyDown = (e) => {

		setTimeout(() => {
			if (e.key === '.') {
				const selection = window.getSelection();
				const range = selection.getRangeAt(0);
				const rect = range.getBoundingClientRect();
				const { top, left, width, height } = rect;

				setCursorPosition({ top: top + height, left: left + width });
				setShowPopup(true);
				setshowvariablepopup(false)
			}

			if (e.key === '@') {

				const selection = window.getSelection();
				const range = selection.getRangeAt(0);
				const rect = range.getBoundingClientRect();
				const { top, left, width, height } = rect;

				setCursorPosition({ top: top + height, left: left + width });

				setshowvariablepopup(true);
				setShowPopup(false)



			}


			const string = getTextBeforeCursor();
			const dotIndex = string.lastIndexOf('.');
			const atIndex = string.lastIndexOf('@')

			//index

			const search = string.slice(dotIndex + 1);
			const variablesearch = string.slice(atIndex + 1)
			if (variablesearch) {
				setvariableSearchText(variablesearch)

			}

			if (search) {
				setSearchText(search);
				// setvariablesearch ()
			}
		}, 0);

		if (showPopup) {
			const { key } = e;
			let templateOptions = filteredTemplates



			switch (key) {
				case "ArrowUp":
					e.preventDefault();
					setSelectedTemplateIndex((prevIndex) =>
						prevIndex > 0 ? prevIndex - 1 : templateOptions.length - 1
					);
					break;
				case "ArrowDown":
					e.preventDefault();
					setSelectedTemplateIndex((prevIndex) =>
						prevIndex < templateOptions.length - 1 ? prevIndex + 1 : 0
					);
					break;
				case "Enter":

					e.preventDefault(setnodenameOnEnter(filteredTemplates[selectedTemplateIndex].name));

					break;


				default:
					break;
			}
		}

		if (showvariablepopup) {
			const { key } = e;
			let variableOptions = variables


			switch (key) {
				case "ArrowUp":
					e.preventDefault();
					setSelectedVariableIndex((prevIndex) =>
						prevIndex > 0 ? prevIndex - 1 : variableOptions.length - 1
					);
					break;
				case "ArrowDown":
					e.preventDefault();
					setSelectedVariableIndex((prevIndex) =>
						prevIndex < variableOptions.length - 1 ? prevIndex + 1 : 0
					);
					break;
				case "Enter":


					if (showvariablepopup) { appendvariable(seacrchedVariableItems[selectedVariableIndex].name) } // change to templet index 

					break;
				default:
					break;
			}
		}

		if (e.key === 'Backspace') {
			e.preventDefault();
			const nodeEntry = Editor.before(editor, editor.selection);
			const node = nodeEntry && Editor.node(editor, nodeEntry.path);

			if (node && node?.[0].removeFull) {
				// If the previous node is of type 'inputComponent', remove it entirely.
				Transforms.removeNodes(editor, { at: nodeEntry });
			} else {
				// Otherwise, remove a single character.
				Editor.deleteBackward(editor);
			}
		}
	};

	useEffect(() => {
		if (!searchText || searchText === "") {
			setShowPopup(false);

		}
		filterTemplates();

		// eslint-disable-next-line
	}, [searchText])


	useEffect(() => {
		if (!variablesearchText || variablesearchText === "") {

			setshowvariablepopup(false)

		}

		searchVariable();
	}, [variablesearchText])

	const filterTemplates = () => {
		const filter = templates.filter((template) => template.name.toLowerCase().startsWith(searchText.toLowerCase()));
		if (filter.length === 0 || searchText.trim() === "") {
			setShowPopup(false);

			setFilteredTemplates([]);

		} else {
			setFilteredTemplates(filter);


		}
	};

	const searchVariable = () => {
		const searchedVariables = variables.filter((variable) => variable.name.toLowerCase().startsWith(variablesearchText.toLowerCase()))
		if (searchedVariables.length === 0 || variablesearchText.trim() === "") {
			setshowvariablepopup(false);

			setseacrchedVariableItems([]);


		} else {
			setseacrchedVariableItems(searchedVariables);

		}
	}

	const [editor] = useState(() => withReact(createEditor()))
	const initialValue = [
		{
			type: 'paragraph',
			children: [
				{ text: '' },
			],
		},
	];

	const renderElement = useCallback((props) => {
	
		switch (props.element.type) {

			case 'inputComponent':
				return <InputComponent {...props} />;
			case 'selectComponent':
				return <SelectComponent {...props} />;
			default:
				return <Element {...props} />;
		}
	}, []);

	const renderLeaf = useCallback(props => {
		return <Leaf {...props} />
	}, [])








	return (
		<div>

			<Slate editor={editor} initialValue={initialValue}  >
				<Toolbar />
				<Editable className="editable-div" renderElement={renderElement} onKeyDown={handleKeyDown} renderLeaf={renderLeaf} />
			</Slate>

			{showPopup && filteredTemplates.length > 0 && (
				<div className={`hover-popup`} style={{ top: cursorPosition.top, left: cursorPosition.left }}>


					<ul>
						{

							filteredTemplates.map((template, index) => (
								<li
									key={template.name}
									onClick={() => {
										appendTemplate(template.name);
									}}
									className={`${selectedTemplateIndex === index ? 'selected' : ''}`}
								>
									{template.name} :  <p className="templetChild" dangerouslySetInnerHTML={{ __html: template.desc }}></p>
								</li>
							))}
					</ul>
				</div>
			)}
			{showvariablepopup && seacrchedVariableItems.length > 0 && (


				<div className={`hover-popup`} style={{ top: cursorPosition.top, left: cursorPosition.left }}>


					<ul>



						{seacrchedVariableItems.map((variable, index) => (
							<li
								key={variable.name}
								onClick={() => {
									appendvariable(variable.name);
								}}
								className={`${selectedVariableIndex === index ? 'selected' : ''}`}
							>
								{variable.name} : <p className="templetChild" dangerouslySetInnerHTML={{ __html: variable.desc }}></p>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
}

export default MagicNote;
