import React, { useState } from "react";
import "./App.css";
import MagicNote from "./Components/MagicNote/MagicNote";
import Toolbar from "./Components/MagicNote/Toolbar/Toolbar";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function App() {

	const [templates, setTemplates] = useState([
		{ name: "template1", desc: "<p>Hello, my name is *** and I am a software engineer.</p>" },
		{ name: "template2", desc: "I like to eat [[apple, banana, orange]]." },
		{ name: "template3", desc: "My favorite sport is ***." },
		{ name: "template4", desc: "My favorite sport is @variable@" },
	]);

	const [variables, setVariables] = useState([
		{ name: "variable", desc: "a boy" },
		{ name: "variable1", desc: "test 2" },
		{ name: "variable2", desc: "test 3" },
		{ name: "variable3", desc: "test 4" },
	]);

	const handleEditTemplates = () => {
		setIsEditTemplatesOpen(true);
	};

	const handleEditVariable = () => {
		setIsEditVariablesOpen(true);
	};

	const [isEditTemplatesOpen, setIsEditTemplatesOpen] = useState(false);
	const [isEditVariablesOpen, setIsEditVariablesOpen] = useState(false)

	//for tool bar 

	

	return (
		<div className="App">
			<div>
				<button onClick={handleEditTemplates}>Templates</button>
				<button onClick={handleEditVariable}>Variables</button>
			</div>
			<div>
				<h2>Editor:</h2>
				<MagicNote templates={templates} variables={variables} />
			</div>

			<EditItemsModal
				isOpen={isEditTemplatesOpen}
				items={templates}
				setItems={setTemplates}
				onClose={() => setIsEditTemplatesOpen(false)}
				title="Templates"
			/>

			<EditItemsModal
				isOpen={isEditVariablesOpen}
				items={variables}
				setItems={setVariables}
				onClose={() => setIsEditVariablesOpen(false)}
				title="Variables"
			/>
		</div>
	);
}

const EditItemsModal = ({ isOpen, items, setItems, onClose, title }) => {
	const [editingItemIndex, setEditingItemIndex] = useState(null);
	const [editingItemValue, setEditingItemValue] = useState("");
	const [editingItemName, setEditingItemName] = useState("");
	const [isAddItemOpen, setIsAddItemOpen] = useState(false);
	const [quillvalue, setquillValue] = useState('');
	const [quillmodule, setquillmodule] = useState({
		toolbar: [
			[{ 'header': [3,4,5,6, false] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
			['link',{ 'color': ['#F00', '#0F0', '#00F', '#000', '#FFF', 'picker'] } , ],
			['clean']
		],
	})
	const [quillformat, setquillformat] = useState([
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 'indent',
		'link', 'color',
		 'font'
	])
	if (!isOpen) {
		return null;
	}

	const handleEditItem = (index) => {
		setEditingItemIndex(index);
		setEditingItemValue(items[index].desc);
		setEditingItemName(items[index].name);
		setquillValue(items[index].desc)
	};

	const handleSaveEditedItem = () => {
		if (editingItemIndex !== null) {
			const updatedItems = [...items];
			updatedItems[editingItemIndex].desc = quillvalue //editingItemValue  ;
			updatedItems[editingItemIndex].name = editingItemName;
			setItems(updatedItems);
			setEditingItemIndex(null);
			setEditingItemValue("");
			setEditingItemName("");
		} else if (editingItemValue !== null && editingItemName !== null) {
			setItems([...items, { name: editingItemName, desc: quillvalue }]);
			setEditingItemIndex(null);
			setEditingItemValue("");
			setEditingItemName("");
			setIsAddItemOpen(false);
		}
	};

	const handleEditCancel = () => {
		setEditingItemIndex(null);
		setEditingItemValue("");
		setEditingItemName("");
		setIsAddItemOpen(false);
	};

	const handleAddItem = () => {
		setIsAddItemOpen(true);
		setquillValue("")
	};

	const isEditing = editingItemIndex !== null || isAddItemOpen;




	return (
		<div className="edit-items-modal">
			<div className="modal-content">
				<h3>{title}</h3>
				{!isEditing ? (
					<>
						<ul>
							{items.map((item, index) => (
								<li  style={{ whiteSpace: 'nowrap' }} key={index} >
									<p>{item.name}{":"}<span className="quillSpan" style={{display:"inline",whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{__html:item.desc}}></span>{""}
									<button onClick={() => handleEditItem(index)}>Edit</button></p>
								</li>
							))}
							<button onClick={onClose}>Close</button>
							<button onClick={handleAddItem}>Add</button>
						</ul>
					</>
				) : (
					<>
						<input
							value={editingItemName}
							onChange={(e) => setEditingItemName(e.target.value)}
							placeholder="Name"
						/>

						<div>
							<ReactQuill theme="snow" value={quillvalue}
								onChange={setquillValue}
								modules={quillmodule}

								formats={quillformat}
								placeholder="Description .. "

							/>
						</div>

						{/* <textarea
							value={editingItemValue}
							onChange={(e) => setEditingItemValue(e.target.value)}
						/> */}
						<button onClick={handleSaveEditedItem}>{isAddItemOpen ? "Save New" : "Save Changes"}</button>
						<button onClick={handleEditCancel}>Cancel</button>
					</>
				)}
			</div>
		</div>
	);
};
export default App;