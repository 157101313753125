import { useEffect, useRef, useState } from "react";

const InputComponent = () => {
	const [show, setShow] = useState(false);
	const [value, setValue] = useState("");
	const inputRef = useRef(null);
	const componentRef = useRef(null);

	const handleChange = (e) => {
		setValue(e.target.value);
	};

	useEffect(() => {
		if(value.length === 0){
			setShow(true);
		}
	}, [value]);

	// useEffect(() => {
	// 	const handleClickOutside = (event) => {
	// 	  if (componentRef.current && !componentRef.current.contains(event.target)) {
	// 		setShow(false);
	// 	  }
	// 	};
	
	// 	const handleKeyPressOutside = (event) => {
	// 	  if (event.key === 'Escape') {
	// 		setShow(false);
	// 	  }
	// 	};
	
	// 	document.addEventListener('mousedown', handleClickOutside);
	// 	document.addEventListener('keydown', handleKeyPressOutside);
	
	// 	return () => {
	// 	  document.removeEventListener('mousedown', handleClickOutside);
	// 	  document.removeEventListener('keydown', handleKeyPressOutside);
	// 	};
	// }, []);

	return (
		<div ref={componentRef} className="input-element"  style={{ userSelect: "none" }} contentEditable={false}>
			{
				show && (
					<input
						type={'text'}
						value={value}
						onChange={handleChange}
						placeholder="***"
						style={{ width: Math.max(20, value.length * 10), display: show ? 'inline' : 'none' }}
						onBlur={(e) => {
							if(value.length > 0){
								setShow(false);
							}
						}}
						ref={inputRef}
					/>
				)
			}
			{
				!show && <span onClick={(e) => { setShow(true); setTimeout(() => {
					inputRef.current.focus(); inputRef.current.setSelectionRange(value.length, value.length);
				}, 10);  }}>{value}</span>
			}
		</div>
	);
};

export default InputComponent;